import * as React from 'react';
import {useContext} from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {createAppState} from "./AppState";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';


export default function DrawSOV() {

    // context
    const {
        projectName,
        drawName,
        isGlobalDisabled
    } = useContext(createAppState)

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // SOV dialog component
    function SOVDialog(props) {

        const { onClose, selectedValue, open } = props;

        const handleClose = () => {
            onClose(selectedValue);
        };

        return (
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{
                    style: {
                        maxWidth: "90vw",
                        maxHeight: "90vh",
                    },
                }}
                disabled={isGlobalDisabled}
            >
                <DialogTitle>
                    {`${projectName} - ${drawName}`}
                </DialogTitle>
                <BudgetTable/>
            </Dialog>
        );
    }

    // created the table
    const BudgetTable = () => {

        const {
            lineItems
        } = useContext(createAppState)

        const formatCurrency = (value) => {
            return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
            }).format(value);
        };

        return (
            <TableContainer component={Paper} style={{ width: "100%"}}>
                <Table size="small">
                    <TableHead style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                        <TableRow>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Line Item</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Original Budget</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Previous Adjustments</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Current Adjustments</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Current Budget </TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Previous Draws</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Current Amount Requested</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Balance to Fund Amount</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>% Remaining</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>% Complete</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Inspection % Complete</TableCell>
                            <TableCell style={{ color: "gray", fontWeight: "bold" }}>Discrepancy Notes</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lineItems.value.map((item) => {

                            // highlight cells where the amount requested is greater than 0 and the variance is greater than 10%
                            const highlight = () => {
                                if (item.requestedAmount && ((item.inspectionPercentComplete - item.percentComplete) < -0.1)) {
                                    return { backgroundColor: "#657375" }
                                }
                                return {}
                            }

                            // create the table
                            return (
                                <TableRow
                                    key={item.id}
                                    style={highlight()}
                                >
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.originalBudgetAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.previousAdjustmentsAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.adjustmentsAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.budgetAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.requestedToDateAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.requestedAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{formatCurrency(item.balanceToFundAmount)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{`${(item.percentRemaining * 100).toFixed(0)}%`}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{`${(item.percentComplete * 100).toFixed(0)}%`}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{`${(item.inspectionPercentComplete * 100).toFixed(0)}%`}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{item.inspectionNotes}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    SOVDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
    };

    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                endIcon={<ChecklistRtlIcon/>}
                disabled={isGlobalDisabled.value}
                sx={{
                    width: "100%"
                }}
            >
                Review Schedule of Values
            </Button>
            <SOVDialog
                selectedValue={"test"}
                open={open}
                onClose={handleClose}
            />
        </>
    );
}
