// react core
import {useContext} from "react";
// 3rd party
import LoadingButton from "@mui/lab/LoadingButton";
import DownloadIcon from '@mui/icons-material/Download';
import PizZip from 'pizzip'
import Docxtemplater from 'docxtemplater';
import {saveAs} from 'file-saver';

// local files
import {createAppState} from "./AppState";
import {signal} from "@preact/signals-react";
import dayjs from "dayjs";

const CreateCoverSheet = () => {

    // loading state, used to disable UI when generating cover sheet
    const isLoading = signal(false)
    // report creation date
    let today = new Date()
    // data context
    const {
        draws,
        selectedDraw,
        drawDate,
        drawPackageDate,
        discrepancies,
        invoiceEarliestDate,
        invoiceLatestDate,
        selectedSubmitter,
        recommendedDisbursement,
        selectedDocuments,
        IRDate,
        invoiceFee,
        invoiceNumber,
        additionalComments,
        totalDeductions,
        stakeholders,
        totalBudget,
        requestedAmount,
        isGlobalDisabled,
        accessToken,
        alert,
        UUID
    } = useContext(createAppState)

    // assemble coversheet when generate button is clicked
    function handleClick() {
        isLoading.value = true
        isGlobalDisabled.value = true
        generateCoverSheet()
    }

    // Create our number formatter.
    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    // convert date string to date object, parse and re-stringify
    const dateFormatter = (date) => {
        const dateObj = dayjs(date)
        return `${dateObj["$M"] + 1}/${dateObj["$D"]}/${dateObj["$y"]}`
    }

    // generate the cover sheet using a template
    function generateCoverSheet() {

        const localBorrower = stakeholders.value.find(stakeholder => stakeholder.role === "BORROWER")
        const localLender = stakeholders.value.find(stakeholder => stakeholder.role === "LENDER")



        const formData = {
            submitter_name: {
                isRequired: true,
                value: localBorrower ? localBorrower.vendor.name : undefined,
                errorMessage: `Missing ${selectedSubmitter} stakeholder in Rabbet`
            },
            submitter_type: {
                isRequired: true,
                value: selectedSubmitter.value,
                errorMessage: `Please select a Requester Type before generating a cover sheet.`
            },
            draw_num: {
                isRequired: true,
                value: draws.value.find(draw => draw.id === selectedDraw.value).name,
                errorMessage: `Please select a Draw # before generating a cover sheet.`
            },
            submitted_date: {
                isRequired: true,
                value: drawDate.value ? dateFormatter(drawDate.value) : undefined,
                errorMessage: `Please update the Draw Date before generating a cover sheet.`
            },
            report_date: {
                isRequired: true,
                value: `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`,
                errorMessage: `Error generating the current date. Please contact the admin for assistance.`
            },
            earliest_invoice_date: {
                isRequired: false,
                value: invoiceEarliestDate.value != null ? dateFormatter(invoiceEarliestDate.value) : undefined,
            },
            latest_invoice_date: {
                isRequired: false,
                value: invoiceLatestDate.value != null ? dateFormatter(invoiceLatestDate.value) : undefined,
            },
            has_invoice_range: {
                isRequired: false,
                value: !!(invoiceEarliestDate.value != null && invoiceLatestDate.value != null)
            },
            has_package_date: {
                isRequired: false,
                value: drawPackageDate.value != null,
            },
            package_date: {
                isRequired: false,
                value: drawPackageDate.value != null ? dateFormatter(drawPackageDate.value) : undefined
            },
            lender: {
                isRequired: true,
                value: localLender ? localLender.vendor.name : undefined,
                errorMessage: `Missing the Lender stakeholder in Rabbet.`
            },
            uuid: {
                isRequired: false,
                value: UUID.value
            },
            has_uuid: {
                isRequired: false,
                value: !!(UUID.value)
            },
            current_budget: {
                isRequired: true,
                value: currencyFormatter.format(totalBudget.value),
                errorMessage: `Missing Current Budget value in Rabbet.`
            },
            requested_amount: {
                isRequired: true,
                value: currencyFormatter.format(requestedAmount.value),
                errorMessage: `Missing a Requested Draw Amount value in Rabbet.`
            },
            recommended_amount: {
                isRequired: true,
                value: recommendedDisbursement.value ? currencyFormatter.format(recommendedDisbursement.value) : undefined,
                errorMessage: `Please enter a Recommended Disbursement amount before generating a cover sheet.`
            },
            total_deductions: {
                isRequired: true,
                value: currencyFormatter.format(totalDeductions.value),
                errorMessage: `Total Deductions is undefined. Please contact the admin for assistance.`
            },
            has_invoice: {
                isRequired: false,
                value: !!(invoiceFee.value > 0 || invoiceNumber.value)
            },
            invoice_number: {
                isRequired: false,
                value: invoiceNumber.value,
                errorMessage: `Please enter an Invoice Fee with the Invoice Number, or leave both empty to exclude.`
            },
            fee: {
                isRequired: false,
                value: currencyFormatter.format(invoiceFee.value),
                errorMessage: `Please enter an Invoice Number with the Invoice Fee, or leave both empty to exclude.`
            },
            supporting_docs: {
                isRequired: false,
                value: selectedDocuments.value
                    .map(document => {
                        return {doc: document}
                    })
            },
            inspectionNotes: {
                isRequired: false,
                value: discrepancies.value.length > 0 ? discrepancies.value
                    .map(comment => {
                        return {discrepancy: comment}
                    }) : [{discrepancy: "None noted this period."}]
            },
            comments: {
                isRequired: false,
                value: additionalComments.value ? additionalComments.value : "None."
            },
            has_inspection: {
                value: !!IRDate.value
            },
            ir_date: {
                isRequired: false,
                value: IRDate.value != null ? dateFormatter(IRDate.value) : undefined
            }
        }

        // authenticate with gateway
        const authHeader = {
            withCredentials: true,
            credentials: 'include',
            headers: {'Authorization': `Bearer ${accessToken.value}`}
        }

        // get template, replace tags and prompt to download
        /*NOTE: The api gateway checks for the actual file name to download using this URL.
        Be sure to include the specific template name as it appears in the Azure container.
        * */
        fetch('https://qdproxy.services/templates/Draw Cover Sheet Template v5.docx', authHeader)
            .then(res => {
                // get response as binary buffer
                return res.arrayBuffer()
            })
            .then(binary => {
                // zip the binary data
                let zip = new PizZip(binary);

                // create a new docxtemplater object using zipped binary
                let doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });

                // throw error if required fields are missing a value
                for (const key in formData) {
                    console.log(`${key}: ${formData[key].value}`)
                    if (formData[key].isRequired && !formData[key].value) {
                        throw new Error(formData[key].errorMessage)
                    }
                }

                if (invoiceNumber.value && !invoiceFee.value) {
                    throw new Error(formData.invoice_number.errorMessage)
                }

                if (!invoiceNumber.value && invoiceFee.value) {
                    throw new Error(formData.fee.errorMessage)
                }


                // set replacement tas with appropriate data
                doc.setData({
                    submitter_name: formData.submitter_name.value,
                    submitted_date: formData.submitted_date.value,
                    report_date: formData.report_date.value,
                    has_package_date: formData.has_package_date.value,
                    package_date: formData.package_date.value,
                    has_invoice_range: formData.has_invoice_range.value,
                    invoice_range: `${formData.earliest_invoice_date.value} - ${formData.latest_invoice_date.value}`,
                    submitter_type: formData.submitter_type.value,
                    draw_num: formData.draw_num.value,
                    lender: formData.lender.value === 'AHP' ? 'AHP/BHCIP' : formData.lender.value,
                    uuid: formData.uuid.value,
                    has_uuid: formData.has_uuid.value,
                    current_budget: formData.current_budget.value,
                    requested_amount: formData.requested_amount.value,
                    recommended_amount: formData.recommended_amount.value,
                    has_invoice: formData.has_invoice.value,
                    invoice_num: formData.invoice_number.value,
                    fee: formData.fee.value,
                    has_inspection: formData.has_inspection.value,
                    ir_date: formData.ir_date.value,
                    supporting_docs: formData.supporting_docs.value,
                    discrepancies: formData.inspectionNotes.value ? formData.inspectionNotes.value : "None noted this period.",
                    comments: formData.comments.value
                })

                // replace tags by custom data and generate the document
                doc.render()
                let outputDoc = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                });

                // prompt user to save document
                saveAs(outputDoc, `${formData.submitter_name.value} ${formData.draw_num.value} Cover Sheet.docx`)

            })
            .catch(error => {
                alert.value = {isOpen: true, message: error.message}
            })
            .finally(() => {
                setTimeout(() => {
                    isLoading.value = false
                    isGlobalDisabled.value = false
                }, 2000)
            })
    }

    return (
        <LoadingButton
            onClick={handleClick}
            endIcon={<DownloadIcon/>}
            loading={isLoading.value}
            loadingPosition="end"
            variant="contained"
            disabled={isGlobalDisabled.value}
            sx={{
                marginTop: 1,
                width: "100%"
            }}
        >
            Cover Sheet
        </LoadingButton>
    )
}

export default CreateCoverSheet