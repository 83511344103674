// react core
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
// 3rd party
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from "@mui/material";
// local files
import LogoutBar from "./LogoutBar";
import {useAuth0} from "@auth0/auth0-react";
import {LoadingPage} from "../pages/LoadingPage";
import BHCIPDataLayout from "./BHCIPDataLayout";
import {createAppState} from "./AppState";
import StatusAlert from "./StatusAlert";

// preact signal

function TabPanel(props) {

    const {children, value, index, ...other} = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ToggleServices() {

    // auth0 react hook
    const {loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently} = useAuth0()
    const {
        accessToken,
        projects,
        cards
    } = useContext(createAppState)

    // check if the user is authenticated, if not, execute login method
    if (!isAuthenticated && !isLoading) {
        loginWithRedirect()
            .then(() => {
                // do nothing
            })
    }

    // set default tab index to 0
    const [value, setValue] = React.useState(0);

    // handle switching between tabs
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    // conditionally render the loading page if the user is not logged in
    if (isAuthenticated) {
        getAccessTokenSilently()
            .then((response) => {
                accessToken.value = response
                const authHeader = {
                    withCredentials: true,
                    credentials: 'include',
                    headers: {'Authorization': `Bearer ${accessToken}`}
                }
                // get Rabbet project data
                const projectsData = fetch('https://qdproxy.services/rabbet/projects', authHeader)
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            throw (response.error())
                        }
                    })
                    .catch((error) => {
                        //FIXME: configure error alert
                        console.log(error)
                    })
                // get Monday.com
                const mondayData = fetch('https://qdproxy.services/monday/BHCIPDraws', authHeader)
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        } else {
                            throw (response.error())
                        }
                    })
                    .catch((error) => {
                        //FIXME: configure error alert
                        console.log(error)
                    })
                Promise.all([
                    projectsData,
                    mondayData
                ])
                    .then((result) => {
                        projects.value = result[0].data

                        // map BHCIP cards
                        const BHCIPCards = result[1].data.boards[2].top_group.items_page.items.length ? result[1].data.boards[2].top_group.items_page.items.map(card => {
                            return {
                                id: card.id,
                                name: card.name,
                                drawDate: card.column_values.find(field => field.id === "date4")?.text || null,
                                drawPackageDate: card.column_values.find(field => field.id === "date2__1")?.text || null,
                                invoiceEarliestDate: card.column_values.find(field => field.id === "date_mknq1kf5")?.text || null,
                                invoiceLatestDate: card.column_values.find(field => field.id === "date_mknqjr4r")?.text || null,
                                IRDate: card.column_values.find(field => field.id === "date84__1")?.text || null,
                                projectPercentComplete: (Number(card.column_values.find(field => field.id === "mirror43__1")?.display_value) / 100) || null,
                                additionalComments: card.column_values.find(field => field.id === "long_text_mknrmme")?.text || ""
                            }
                        }) : []
                        // map White Glove cards
                        const WGCards = result[1].data.boards[0].top_group.items_page.items.length ? result[1].data.boards[0].top_group.items_page.items.map(card => {
                            return {
                                id: card.id,
                                name: card.name,
                                drawDate: card.column_values.find(field => field.id === "date4")?.text || null,
                                drawPackageDate: null,
                                invoiceEarliestDate: null,
                                invoiceLatestDate: null,
                                IRDate: card.column_values.find(field => field.id === "date84__1")?.text || null,
                                projectPercentComplete: (Number(card.column_values.find(field => field.id === "mirror7__1")?.display_value) / 100) || null,
                                additionalComments: card.column_values.find(field => field.id === "long_text_mkpfww2y")?.text || ""
                            }
                        }) : []
                        // map CCE cards
                        const CCECards = result[1].data.boards[1].top_group.items_page.items.length ? result[1].data.boards[1].top_group.items_page.items.map(card => {
                            return {
                                id: card.id,
                                name: card.name,
                                drawDate: card.column_values.find(field => field.id === "date4")?.text || null,
                                drawPackageDate: null,
                                invoiceEarliestDate: null,
                                invoiceLatestDate: null,
                                IRDate: card.column_values.find(field => field.id === "date84__1")?.text || null,
                                projectPercentComplete: (Number(card.column_values.find(field => field.id === "lookup_mkpfg0dz")?.display_value) / 100) || null,
                                additionalComments: card.column_values.find(field => field.id === "long_text_mkpf7jeb")?.text || ""
                            }
                        }) : []
                        cards.value = [...WGCards, ...BHCIPCards, ...CCECards]
                        console.log(cards.value)
                    })
            })

        return (
            <Container>
                <Box
                    sx={{
                        paddingTop: 2,
                        borderBottom: 1,
                        borderColor: 'divider',
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="draw tool tabs"
                    >
                        <Tab label="Draw Tool" {...a11yProps(0)}/>
                    </Tabs>
                </Box>
                <TabPanel
                    value={value}
                    index={0}
                >
                    <BHCIPDataLayout/>
                </TabPanel>
                <StatusAlert/>
                <LogoutBar/>
            </Container>
        )
    } else {
        return (<LoadingPage/>)
    }
}