// react core
import {useContext} from 'react';
// local files
import SupportingDocuments from "./SupportingDocuments";
import AdditionalComments from "./AdditionalComments";
import StatusAlert from "./StatusAlert";
import FundingInformation from "./FundingInformation";
import DrawSOV from "./DrawSOV";
// 3rd party modules
import {Container, Divider, Stack, TextField, Typography} from "@mui/material";
import SelectCard from "./SelectCard";
import SelectProject from "./SelectProject";
import SelectDraw from "./SelectDraw";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {createAppState} from "./AppState";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import SelectSubmitter from "./SelectSubmitter";
import CreateCoverSheet from "./CreateCoverSheet";
import SubmitBHCIPData from "./SubmitBHCIPData";
import dayjs from "dayjs";
import {DateField} from "@mui/x-date-pickers";

export default function BHCIPDataLayout() {

    const {
        drawDate,
        drawPackageDate,
        invoiceEarliestDate,
        invoiceLatestDate,
        IRDate,
        selectedDraw,
        projectPercentComplete,
        UUID,
        B1ID
    } = useContext(createAppState)

    return (
        <Container maxWidth="sm">
            <Stack
                direction="column"
                spacing={1}
            >
                <Typography
                    variant="caption"
                    color="white"
                    align="left"
                >
                    Select Project, Draw and Card:
                </Typography>
                <SelectProject/>
                <SelectDraw/>
                <SelectCard/>
                <Divider variant="middle">
                </Divider>
                {/*Draw Date Received and Draw Package Received Date fields*/}
                <Stack
                    direction="row"
                    spacing={1}
                    id={'contains-draw-dates'}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            label="Draw Received"
                            value={dayjs(drawDate.value)}
                            // onChange={(newValue) => drawDate.value = newValue}
                            id="draw-received"
                            key={`${selectedDraw.value}-draw-date`}
                            disabled={true}
                            sx={{width:'100%'}}
                            error={false}
                        />
                        <DateField
                            label="Complete Package Received"
                            value={dayjs(drawPackageDate.value)}
                            // onChange={(newValue) => drawPackageDate.value = newValue}
                            id="package-received"
                            key={`${selectedDraw.value}-package-date`}
                            disabled={true}
                            error={false}
                            sx={{width:'100%'}}
                        />
                    </LocalizationProvider>
                </Stack>
                {/*Invoice date range fields*/}
                <Stack
                    direction="row"
                    spacing={1}
                    id={'contains-invoice-date-range'}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            label="Earliest Invoice Date"
                            value={dayjs(invoiceEarliestDate.value)}
                            // onChange={(newValue) => invoiceEarliestDate.value = newValue}
                            id="invoice-earliest-date"
                            key={`${selectedDraw.value}-earliest-invoice-date`}
                            disabled={true}
                            error={false}
                            sx={{width:'100%'}}
                        />
                        <DateField
                            label="Latest Invoice Date"
                            value={dayjs(invoiceLatestDate.value)}
                            // onChange={(newValue) => invoiceLatestDate.value = newValue}
                            id="invoice-latest-date"
                            key={`${selectedDraw.value}-latest-invoice-date`}
                            disabled={true}
                            error={false}
                            sx={{width:'100%'}}
                        />
                    </LocalizationProvider>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    id={'contains-inspection-items'}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            label="Inspection Date"
                            value={dayjs(IRDate.value)}
                            // onChange={(value) => IRDate.value = value}
                            id="ir-date"
                            key={`${selectedDraw.value}-ir-date`}
                            disabled={true}
                            error={false}
                            sx={{width:'100%'}}
                        />
                        <TextField
                            label="Project % Complete"
                            id="project-percent-complete"
                            value={`${((projectPercentComplete?.value ?? 0) * 100).toFixed(0)}%`}
                            // onChange={(e) => projectPercentComplete.value = parseFloat(e.target.value) / 100}
                            disabled={true}
                            error={false}
                            sx={{width:'100%'}}
                        />
                    </LocalizationProvider>
                </Stack>
                <TextField
                    id="bhcip-uuid"
                    label="BHCIP UUID"
                    value={UUID.value}
                    sx={{width:'100%'}}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                />
                <TextField
                    id="bhcip-b1-budget-id"
                    label="BHCIP B1 Budget ID"
                    value={B1ID.value}
                    sx={{width:'100%'}}
                    fullWidth
                    disabled={true}
                    variant="outlined"
                />
                <SelectSubmitter/>
                <SupportingDocuments/>
                <FundingInformation/>
                <AdditionalComments/>
                <DrawSOV/>
                <Divider variant="middle">
                </Divider>
                <Stack
                    direction="row"
                    spacing={1}
                    id={'contains-action-buttons'}
                >
                    <CreateCoverSheet/>
                    <SubmitBHCIPData/>
                </Stack>
                <StatusAlert/>
            </Stack>
        </Container>
    )
}