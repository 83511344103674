import {batch, computed, effect, signal} from "@preact/signals-react";
import {createContext} from "react";
import {MenuItem} from "@mui/material";
import {drawMenuItems} from "./SelectDraw";
import {cardMenuItems} from "./SelectCard";

export const createAppState = createContext()

export default function AppState(props) {

    // primitive signals
    // project
    const projects = signal([])
    const selectedProject = signal("")
    const projectName = signal("")
    const totalBudget = signal("")
    const requestedAmount = signal("")
    const recommendedDisbursement = signal("")
    const UUID = signal("")
    const B1ID = signal("")
    const stakeholders = signal([])
    const selectedSubmitter = signal("Borrower")
    const projectPercentComplete = signal(null)
    // draw
    const draws = signal([])
    const selectedDraw = signal("")
    const drawName = signal("")
    const drawDate = signal(null)
    const drawPackageDate = signal(null)
    const lineItems = signal([])
    const invoiceEarliestDate = signal(null)
    const invoiceLatestDate = signal(null)
    const invoiceFee = signal("")
    const invoiceNumber = signal("")
    const additionalComments = signal("")
    const discrepancies = signal([])
    // inspection
    const IRDate = signal(null)
    const originalCompletionDate = signal(null)
    const revisedCompletionDate = signal(null)
    // Monday cards
    const cards = signal({})
    const selectedCard = signal("")
    const selectedDocuments = signal([])
    // app-specific
    const alert = signal({isOpen: false, severity: "error", message: ""})
    const accessToken = signal("")
    const isGlobalDisabled = signal(true)

    // reset fields
    const resetDrawFields = () => {
        effect(() => {
            batch(() => {
                selectedCard.value = ""
                drawDate.value = null
                drawPackageDate.value = null
                invoiceEarliestDate.value = null
                invoiceLatestDate.value = null
                drawName.value = ""
                lineItems.value = []
                recommendedDisbursement.value = ""
                invoiceNumber.value = ""
                invoiceFee.value = ""
                IRDate.value = null
                additionalComments.value = ""
                requestedAmount.value = 0
                discrepancies.value = []
                selectedDocuments.value = []
                projectPercentComplete.value = null
            })
        })
    }

    // selecting a new project
    effect(() => {
        // set draws selection menu upon selection of a project
        if (selectedProject.value && accessToken.value) {

            isGlobalDisabled.value = true
            const authHeader = {
                withCredentials: true,
                credentials: 'include',
                headers: {'Authorization': `Bearer ${accessToken}`}
            }
            // set project name
            projectName.value = projects.value.find(project => project.id === selectedProject.value).name

            // get draws for selected project
            fetch(`https://qdproxy.services/rabbet/draws/${selectedProject.value}`, authHeader)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw (response.error())
                    }
                })
                .then(data => {
                    // set draws
                    batch(() => {
                        // find selected project data
                        const projectData = projects.value.find(project => project.id === selectedProject.value)
                        // temporarily disable UI
                        isGlobalDisabled.value = true
                        // reset selected draw
                        selectedDraw.value = ""
                        // reset current budget
                        totalBudget.value = projectData.budgetAmount
                        const localUUID = projectData.customFields.customId
                        // set UUID (for BHCIP)
                        UUID.value = localUUID || ""
                        // set B1 ID (for BHCIP)
                        B1ID.value = projectData.customFields.b1Id || ""
                        // set requester type
                        localUUID ? selectedSubmitter.value = "Sponsor" : selectedSubmitter.value = "Borrower"
                        // set original completion date
                        originalCompletionDate.value = projectData.customFields.origdateofcomp
                        // revised date of completion
                        revisedCompletionDate.value = projectData.customFields.revdateofcomp
                        // reset draw-level fields
                        resetDrawFields()
                        if (data.data.length <= 0) {
                            draws.value = []
                            throw new Error("No draws have been entered for this project.")
                        }
                        // reset selected Monday card
                        selectedCard.value = ""
                        // set draw menu items
                        draws.value = data.data
                        drawMenuItems.value = draws.value.map((draw) => {
                            return (
                                <MenuItem
                                    key={draw.id}
                                    value={draw.id}
                                >
                                    {draw.name}
                                </MenuItem>
                            )
                        })
                        // set card menu items
                        cardMenuItems.value = cards.value.map((card) => {
                            return (
                                <MenuItem
                                    key={card.id}
                                    value={card.id}
                                >
                                    {card.name}
                                </MenuItem>
                            )
                        })
                    })
                })
                .catch((error) => {
                    alert.value = {isOpen: true, message: error.message}
                })
                .finally(() => {
                    isGlobalDisabled.value = false
                })

            // get stakeholders for selected project
            fetch(`https://qdproxy.services/rabbet/stakeholders/${selectedProject.value}`, authHeader)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw (response.error())
                    }
                })
                .then(data => {
                    // set stakeholders
                    stakeholders.value = data.data
                })
                .catch((error) => {
                    alert.value = {isOpen: true, message: error.message}
                    return []
                })
        }
    })

    // selecting a new draw
    effect(() => {
        if (selectedDraw.value && draws.value.length > 0) {
            batch(() => {
                resetDrawFields()
                selectedCard.value = ""
                requestedAmount.value = draws.value.find(draw => draw.id === selectedDraw.value).requestedAmount
                recommendedDisbursement.value = requestedAmount.value
                mapLineItems()
                mapDiscrepancies()
                drawName.value = draws.value.find(draw => draw.id === selectedDraw.value).name
                isGlobalDisabled.value = false
            })
        }
        return 0
    })

    // calculate deductions
    const totalDeductions = computed(() => {
        if (requestedAmount.value > 0 && recommendedDisbursement.value > 0) {
            return requestedAmount.value - recommendedDisbursement.value
        }
        return 0
    })

    // calculate percent remaining value
    const calculatePercentRemaining = (currentBudget, requestedToDateAmount) => {
        if (currentBudget === 0) return 0; // avoid division by zero
        return ((currentBudget - requestedToDateAmount) / currentBudget)
    };

    // calculate percent complete value
    const calculatePercentComplete = (currentBudget, requestedToDateAmount) => {
        if (currentBudget === 0) return 0;
        return (requestedToDateAmount / currentBudget)
    }

    // round decimal places
    const roundDecimal = (num) => {
        return (Math.ceil(num * 100) / 100).toFixed(2)
    }

    // map line items
    // it is important to keep the raw data here, and only transform the style in the UI value field
    const mapLineItems = () => {
        lineItems.value = draws.value.find(draw => draw.id === selectedDraw.value).lineItems.map(item => {
            return {
                id: item.id,
                name: item.name,
                division: item.division.name,
                originalBudgetAmount: item.originalBudgetAmount,
                budgetAmount: item.budgetAmount,
                adjustmentsAmount: item.adjustmentsAmount,
                previousAdjustmentsAmount: item.previousAdjustmentsAmount,
                requestedAmount: item.requestedAmount,
                // modified to match the Previous Draws (Net) value in the DT sheet
                requestedToDateAmount: item.requestedToDateAmount - item.requestedAmount,
                balanceToFundAmount: item.budgetAmount - item.requestedToDateAmount,
                // modified to match what is submitted in the EDR, avoid division by 0
                percentRemaining: Number(roundDecimal(calculatePercentRemaining(item.budgetAmount, item.requestedToDateAmount))),
                // modified to match what is submitted in the EDR, avoid division by 0
                percentComplete: Number(roundDecimal(calculatePercentComplete(item.budgetAmount, item.requestedToDateAmount))),
                inspectionPercentComplete: item.inspectionPercentComplete || 0,
                hardCosts: item.hardCosts,
                inspectionNotes: item.inspectionNotes
            }
        })
    }

    // map discrepancy comments for the covers sheet
    const mapDiscrepancies = () => {
        discrepancies.value = draws.value.find(draw => draw.id === selectedDraw.value).lineItems
            .filter(item => item.inspectionNotes !== null)
            .map(item => item.inspectionNotes)
    }

    // selecting a new Monday card
    effect(() => {
        if (selectedCard.value) {
            const cardData = cards.value.find(card => card.id === selectedCard.value)
            batch(() => {
                drawDate.value = cardData.drawDate
                drawPackageDate.value = cardData.drawPackageDate
                invoiceEarliestDate.value = cardData.invoiceEarliestDate
                invoiceLatestDate.value = cardData.invoiceLatestDate
                IRDate.value = cardData.IRDate
                projectPercentComplete.value = cardData.projectPercentComplete
                additionalComments.value = cardData.additionalComments
            })
        }
    })

    return (
        <createAppState.Provider value={{
            projects,
            selectedProject,
            projectName,
            cards,
            selectedCard,
            selectedDraw,
            drawName,
            lineItems,
            invoiceEarliestDate,
            invoiceLatestDate,
            drawDate,
            drawPackageDate,
            selectedSubmitter,
            recommendedDisbursement,
            selectedDocuments,
            IRDate,
            projectPercentComplete,
            originalCompletionDate,
            revisedCompletionDate,
            invoiceFee,
            invoiceNumber,
            additionalComments,
            discrepancies,
            alert,
            isGlobalDisabled,
            accessToken,
            totalDeductions,
            stakeholders,
            totalBudget,
            requestedAmount,
            draws,
            UUID,
            B1ID,
        }}>
            {props.children}
        </createAppState.Provider>
    )
}