import {useContext} from 'react';
import Snackbar from '@mui/material/Snackbar';
import {createAppState} from "./AppState";
import {Alert} from "@mui/lab";

export default function StatusAlert() {

    const {
        alert,
    } = useContext(createAppState)


    const handleClose = () => {
        alert.value = {isOpen: false, severity: "", message: ""}
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            open={alert.value.isOpen}
            onClose={handleClose}
            autoHideDuration={8000}
        >
            <Alert
                severity={alert.value.severity}
            >
                {alert.value.message}
            </Alert>
        </Snackbar>
    )
}