import {BottomNavigation, BottomNavigationAction} from "@mui/material";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import {useAuth0} from "@auth0/auth0-react";

export default function LogoutBar() {

    const {logout} = useAuth0();

    // logout function using Auth0 built-in hooks
    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    }

    return (
        <BottomNavigation
            showLabels
            onChange={handleLogout}
            sx={{
                marginBottom: 2
            }}
        >
            <BottomNavigationAction
                label={"Logout"}
                value={"logout"}
                icon={<LogoutRoundedIcon/>}
            />
        </BottomNavigation>
    );
}