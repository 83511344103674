import {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createAppState} from "./AppState";
import {signal} from "@preact/signals-react";

export const cardMenuItems = signal([])

export default function SelectCard() {

    const {
        selectedCard,
        selectedProject,
        isGlobalDisabled
    } = useContext(createAppState)

    return (
        <FormControl fullWidth>
            <InputLabel id="cards-menu" required>Card</InputLabel>
            <Select
                id="select-bhcip-cards"
                value={selectedCard.value}
                label="card *"
                onChange={(event) => {
                    selectedCard.value = event.target.value
                }}
                disabled={!selectedProject.value || isGlobalDisabled.value}
                variant="filled"
            >
                {cardMenuItems.value}
            </Select>
        </FormControl>
    );
}