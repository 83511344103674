import {useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {createAppState} from "./AppState";

export default function SelectProject() {

    const {
        projects,
        selectedProject
    } = useContext(createAppState)

    return (
        <FormControl fullWidth>
            <InputLabel id="requester-types" required>Project</InputLabel>
            <Select
                id="select-projects"
                value={selectedProject.value}
                label="Project *"
                onChange={(event) => {
                    selectedProject.value = event.target.value
                }}
                disabled={projects.value.length <= 0}
                variant="filled"
            >
                {projects.value.map((project) => {
                    return (
                        <MenuItem
                            value={project.id}
                            key={project.id}
                        >
                            {project.name}
                        </MenuItem>)
                })}
            </Select>
        </FormControl>
    );
}