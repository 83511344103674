import {useContext} from 'react';
import {useTheme} from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {InputAdornment, Stack, TextField, Typography} from "@mui/material";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";
import {createAppState} from "./AppState";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const documents = [
    'Draw Tracking Schedule',
    'Disbursement Request Form with Reallocation Requests (if applicable)',
    'Supporting Documentation'
];

function getStyles(name, documentName, theme) {
    return {
        fontWeight:
            documentName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function SupportingDocuments() {

    const theme = useTheme();
    const {
        selectedDocuments,
        invoiceFee,
        invoiceNumber,
        isGlobalDisabled
    } = useContext(createAppState)

    const handleChange = (event) => {
        const {
            target: {value},
        } = event;
        selectedDocuments.value = typeof value === 'string' ? value.split(',') : value
    };

    return (
        <Stack
            direction="column"
            spacing={1}
        >
            <Typography
                variant="caption"
                color="white"
                align="left"
            >
                Included documents (leave any fields empty to exclude):
            </Typography>
            <Stack
                direction="row"
                spacing={1}
            >
                <TextField
                    id="invoice-number"
                    label="Invoice Number"
                    onChange={(event) => invoiceNumber.value = event.target.value}
                    value={invoiceNumber.value}
                    fullWidth
                    disabled={isGlobalDisabled.value}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">#</InputAdornment>,
                    }}
                />
                <FormControl fullWidth>
                    <CurrencyTextField
                        label="Invoice Amount"
                        variant="outlined"
                        value={invoiceFee.value}
                        currencySymbol="$"
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        onChange={(event, fee) => invoiceFee.value = fee}
                        disabled={isGlobalDisabled.value}
                    />
                </FormControl>
            </Stack>
            <FormControl fullWidth>
                <InputLabel id="additional-documents">Additional Documents</InputLabel>
                <Select
                    id="additional-documents"
                    multiple
                    value={selectedDocuments.value}
                    onChange={handleChange}
                    input={<OutlinedInput label="Additional Documents"/>}
                    MenuProps={MenuProps}
                    disabled={isGlobalDisabled.value}
                    variant="outlined"
                >
                    {documents.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, documents, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    );
}