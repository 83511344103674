// react core
import {useContext, useState} from "react";
// 3rd party
import LoadingButton from "@mui/lab/LoadingButton";
import {Send} from "@mui/icons-material";

// local files
import {createAppState} from "./AppState";
import {signal} from "@preact/signals-react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {DialogActions} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import StatusAlert from "./StatusAlert";

const SubmitBHCIPData = () => {

    // loading state, used to disable UI when generating cover sheet
    const isLoading = signal(false)

    // data context
    const {
        selectedProject,
        selectedDraw,
        drawName,
        lineItems,
        invoiceEarliestDate,
        invoiceLatestDate,
        drawDate,
        drawPackageDate,
        IRDate,
        originalCompletionDate,
        revisedCompletionDate,
        projectPercentComplete,
        additionalComments,
        isGlobalDisabled,
        totalBudget,
        requestedAmount,
        recommendedDisbursement,
        UUID,
        B1ID,
        accessToken,
        alert
    } = useContext(createAppState)

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
    };

    // function findEmptyFields(obj) {
    //     return Object.keys(obj).filter(key => {
    //         const value = obj[key];
    //         // skip additional comments, as they are not required
    //         if (key === "additionalComments") {
    //             return ""
    //         }
    //         return value === null || value === undefined || value === '';
    //     });
    // }

    function findEmptyFields(obj, path = '') {
        let results = []

        // ignore fields that are not required
        const ignoreKeys = [
            'additionalComments',
            'lineItems',
            'project.projectPercentComplete',
            'inspection.inspectionDate'
        ]

        for (const key in obj) {
            if (!obj.hasOwnProperty(key)) continue;
            if (ignoreKeys.includes(key)) continue;

            const fullPath = path ? `${path}.${key}` : key;
            const value = obj[key];

            const isEmptyObject = val => typeof val === 'object' && val !== null && !Array.isArray(val) && Object.keys(val).length === 0;
            const isEmptyArray = val => Array.isArray(val) && val.length === 0;

            if (
                value === null ||
                value === undefined ||
                value === '' ||
                isEmptyArray(value) ||
                isEmptyObject(value)
            ) {
                results.push(fullPath);
            } else if (typeof value === 'object' && value !== null) {
                results = results.concat(findEmptyFields(value, fullPath));
            }
        }

        return results;
    }


    // create and download json file
    function downloadJSON(data, filename) {
        const jsonString = JSON.stringify(data, null, 2); // Convert object to JSON string
        const blob = new Blob([jsonString], { type: 'application/json' }); // Create a Blob
        const link = document.createElement('a'); // Create an anchor element
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        link.download = filename; // Set the filename
        document.body.appendChild(link); // Append to DOM
        link.click(); // Trigger the download
        document.body.removeChild(link); // Remove from DOM
    }

    // send data upon clicking
    function sendBHCIPData() {
        const data = {
            id: selectedDraw.value,
            name: drawName.value,
            requestedAmount: requestedAmount.value,
            approvedAmount: recommendedDisbursement.value,
            dateReceived: drawDate.value,
            invoiceDateEarliest: invoiceEarliestDate.value,
            invoiceDateLatest: invoiceLatestDate.value,
            completePackageDate: drawPackageDate.value,
            additionalComments: additionalComments.value,
            project: {
                id: selectedProject.value,
                sponsorUUID: UUID.value,
                B1Id: B1ID.value,
                budgetAmount: totalBudget.value,
                projectPercentComplete: projectPercentComplete.value,
            },
            inspection: {
                inspectionDate: IRDate.value,
                originalCompletionDate: originalCompletionDate.value,
                revisedCompletionDate: revisedCompletionDate.value,
            },
            lineItems: lineItems.value
        }

        try {
            isGlobalDisabled.value = true

            const missingItems = findEmptyFields(data);

            if (missingItems.length > 0) {
                throw new Error(`The following required items are missing: ${missingItems.join(", \n")}`);
            }

            // download json
            // downloadJSON(data, `${projectName.value} - ${drawName.value} Sample Data.json`);

            const authHeader = {
                withCredentials: true,
                credentials: 'include',
                headers: {'Authorization': `Bearer ${accessToken}`},
                method: "POST",
            }

            // submit to AHP
            // get access token then submit data object
            fetch('https://qdproxy.services/AHP/dev/auth', authHeader)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(result => {
                    // AHP draw payload
                    const AHPInit = {
                        withCredentials: true,
                        credentials: 'include',
                        headers: {
                            'Authorization': `Bearer ${result.access_token}`,
                            'Content-Type': 'application/json'
                        },
                        method: "POST",
                        // API expects to see {data: data} object, or else you get a 500 internal server error
                        body: JSON.stringify({data: data})
                    }
                    fetch(`https://qdproxy.services/AHP/dev/submit-draw`, AHPInit)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`HTTP error! status: ${response.status}`);
                            }
                            return response.json();
                        })
                        .then(result => {
                            if (!result.ok) {
                                alert.value = {isOpen: true, severity: "success", message: `Successfully submitted ${data.name} for ${data.project.sponsorUUID}.`};
                                downloadJSON(data, `${data.project.sponsorUUID} - ${data.name}.json`);
                            }
                        })
                        .catch(error => {
                            alert.value = {isOpen: true, severity: "error", message: error.message}
                        })
                })
                .catch(error => {
                    console.error('Error submitting draw:', error);
                })
                .finally(() => {
                    setOpen(false);
                })
        }
        catch (error) {
            alert.value = {isOpen: true, severity: "error", message: error.message}
        }
        finally {
            setOpen(false);
            isGlobalDisabled.value = false
        }
    }

        return (
        <>
            <LoadingButton
                onClick={handleClickOpen}
                endIcon={<Send/>}
                loading={isLoading.value}
                loadingPosition="end"
                variant="contained"
                disabled={isGlobalDisabled.value}
                sx={{
                    marginTop: 1,
                    width: "100%"
                }}
            >
                Submit Data
            </LoadingButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={"xs"}
                fullWidth={true}
            >
                <DialogTitle id="confirm-dialog-title" align="center">
                    {"Confirm submission?"}
                </DialogTitle>
                <DialogActions sx={{justifyContent: "center"}}>
                    <Button onClick={handleClose} autoFocus={true}>
                        Cancel
                    </Button>
                    <Button onClick={sendBHCIPData} variant="contained">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <StatusAlert/>
        </>
    )
}

export default SubmitBHCIPData